<template>
  <div class="about">
   <div style='float: top; width: 30%; vertical-align: middle;'>
		        <div style='vertical-align: middle; text-align: center; font-size: 12pt; font-weight: bold;width: 100%;'>TENANT ADMIN 1.0</div>
		        <div style='vertical-align: middle; text-align: center; font-size: 9pt; font-weight: bold;width: 100%;'>CONTROLS</div>
		        <br/>
   </div>
    
    <div style='padding-left: 2pt;padding-right: 20pt;height: 28pt!important; display: inline-block; '>
    <div style='height: 100% !important; display: inline-block; padding-top:5pt;padding-right:5pt;'><b>Active backend</b></div>
     {{activeServerName}}<br/>
     
          </div>
    <div style='border-bottom: 1pt solid #4ac; margin: 5px;'></div>
          <button class='button myButton' title='reset ignite in-memory database (cache)' @click='resetCache()'>RESET CACHE</button>
    		<button class='button myButton' title='reload this screen' @click='dbCopyPrepare()'><mdicon :size="15" name="reload" /></button>
     
	<div style='border-bottom: 1pt solid #44a; margin: 5px;'></div>
    
    <input type="text" disabled="true" v-model="copyRequest.from"/> to <input type="text"  v-model="name"/><input type="text"  v-model="copyInfo.tenant.shortname"/>
    
    <div v-if="data && data.tenant && data.tenant.id">
    	<b>new Tenant: {{data.tenant.name}} with ID {{data.tenant.id}} created.</b>
    	<hr/>
    	<table>
    	<tr><td colspan=2><A :href="createURL(data.tenant)" target='_blank'>{{createURL(data.tenant)}}</A></td></tr>
    	<tr><td>identifier</td><td><div style='display: inline-flex; width: 100% !important;'><InputText disabled v-model='data.tenant.identifier'/></div></td></tr>
    	<tr><td>URL</td><td><div style='display: inline-flex; width: 100% !important;'><InputText disabled v-model='url'/></div></td></tr>
		<tr v-if="data && data.sysAdmin && data.sysAdmin.id"><td>admin login</td><td><div style='display: inline-flex; width: 100% !important;'><InputText disabled v-model='data.sysAdmin.login'/></div></td></tr>
		<tr v-if="data && data.sysAdmin && data.sysAdmin.id"><td>password</td><td><div style='display: inline-flex; width: 100% !important;'><InputText disabled v-model='data.sysAdmin.password'/></div></td></tr>
		</table>
    	<br/>
    </div>
    <div v-else-if="copyInfo && copyInfo.tenant">
    	Tenant: {{ copyInfo.tenant.name }} [{{ copyInfo.tenant.shortname }}]<br/>
    	<table>
    	<tr>
    		<td>Copy as baseline</td><td><app-switch v-model="baseline" :checked="baseline"/></td>
    	</tr>
    	<tr>
    		<td>Copy User</td><td><app-switch :disabled="copyRequest.baseline" v-model="copyRequest.copyUsers" :checked="copyRequest.copyUsers && !baseline"/></td>
    	</tr>
    	<tr>
    		<td>Copy History</td><td><app-switch :disabled="copyRequest.baseline" v-model="copyRequest.copyHistory" :checked="copyRequest.copyHistory && !baseline"/></td>
    	</tr>
    	<tr>
    		<td>Copy Campaigns</td><td><app-switch :disabled="copyRequest.baseline" v-model="copyRequest.copyCampaigns" :checked="copyRequest.copyCampaigns && !baseline"/></td>
    	</tr>
		<tr>
    		<td>Copy Spots</td><td><app-switch :disabled="copyRequest.baseline" v-model="copyRequest.copySpots" :checked="copyRequest.copySpots && !baseline"/></td>
    	</tr>
    	<tr><td colpan=2 style='height: 7pt;'></td>
    	</tr>
    	<tr v-for="(a,idx) in copyInfo.accessPoints" :key="'a'+idx">
    	    
    	    <td> {{ a.name }}</td><td><app-switch v-model="accessPointsActive[idx]" :checked="accessPointsActive[idx]"/></td>
    	</tr>
    	</table>
    	<hr/>
    	<table v-for="(t,i) in tenants" :key="'t'+i" style='width:40%' border=0>
    	
    	<tr >
    	<td colspan=2><A :href="createURL(t)" target='_blank'><b>{{t.name}}</b> from {{getTime(t.created)}}</A></td>
    	</tr>
    	<tr><td>identifier</td><td><div style='display: inline-flex; width: 100% !important;'><InputText disabled v-model='t.identifier'/></div></td></tr>
    	<tr><td>URL</td><td><div style='display: inline-flex; width: 100% !important;'><InputText disabled v-model='url'/></div></td></tr>
		</table>	
    	<br/>
    </div>
    
    <button class='button myButton' @click='dbCopy()'>COPY</button>&nbsp;
    <GConfirm ref='confirm'/>
    <ProgressBar v-if="showProgressBar" :generalInfo=pbTitle :action=pbAction @action=action></ProgressBar>
  </div>
  
</template>
<script>
// import axios from 'axios';

import { HTTP, copyAPI,APIURL,serviceAPI,showError,rebuildAPI,testServer } from '@/variables'
var momentTZ = require('moment-timezone');
import ProgressBar from '@/components/ProgressBar';
import GConfirm from '@/components/GConfirm';
import InputText from '@/components/inputElements/InputText';
import Switch from '@/components/Switch';
import axios from 'axios';
export default {
  name: 'resetCache',
  components : {
    ProgressBar, 'app-switch': Switch, InputText, GConfirm
  },
  data () { 
    return {
        pbAction: "",
        activeServer: "",
        activeServerName: "**",
        servers: [],
        pbTitle: "",
        name: "Test",
        baseline: false,
        copyRequest: {
            from: "xxx",
            fromId: 0,
        	name: "xxx",
        	baseline: false,
        	copyUsers: true,
        	copySpots: false,
        	copyHistory: false,
			copyCampaigns: true,
        	accessPoints: []
        },
        accessPointsActive: [],
        copyInfo: {
            
        },
      	data: {},
      	tenants: [],
      	url: "",
      	showProgressBar: false
      }
    },
    computed: {
   
   },
  watch:
  {
     name : function() {
        this.setName();
     },
     activeServer: function() 
     {
    	 let that = this;
    	 let parts = this.activeServer.split(':');
    	 if ( parts[0] != sessionStorage.server || parts[1] != sessionStorage.port)
    	 {
	    	 testServer( parts[0], parts[1]).then( p => {
	    		 sessionStorage.server = parts[0];
	        	 sessionStorage.port = parts[1];
	        	 this.activeServerName = p;
	        	 that.$toast.success("New active server: "+ p, 'Ok', { timeout: 1000, position: "topRight" });
	      
    	 	}).catch( e => { that.$toast.error("switch to this server not possiblke... maybe down", 'Ok', { timeout: 1000, position: "topRight" });})
    	 }
     },
     baseline: function() { 
 		if ( this.baseline)
 		{
	    		this.copyRequest.copyUsers = false;
	    		this.copyRequest.copySpots = false;
	    		this.copyRequest.copyHistory = false;
				this.copyRequest.copyCampaigns = false;
 		}
 		this.copyRequest.baseline = this.baseline;
 	},
  },
  methods: {
     setName( ) {
     	this.copyRequest.name = this.name;

     	this.dbCopyPrepare()
     },
     getTime(time)
     {
    	 return momentTZ(time).tz(sessionStorage.timezone).format(sessionStorage.dateFormat+ " "+sessionStorage.timeFormat);
     },
     resetCache()
     {
         this.pbAction = "resetCacheIntern";
         this.pbTitle = "Reset complete cache";
  	     this.showProgressBar = true;
     },
     dbCopy()
     {
        let that = this;
        let msg = 'Copy database from '+this.copyRequest.from+' to '+this.name;
     	this.$refs.confirm.confirm( {text: msg, title: "Copy data", button1: "Cancel", button2: "Copy"}).then(x => {
	        		this.pbAction = "dbCopyIntern";
				        this.pbTitle = "Copy tenant-data";
				  	    this.showProgressBar = true;
		       }).catch((err) => { 
		       		
		       });
         
     },
     action( what, myId, api)
     {
    	this[what](myId, api)
     },
     resetCacheIntern(myId, api) 
	 {
		 let that = this;


         api.post( copyAPI+"/resetCache/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+myId)
            .then( response => 
            { 
			   that.showProgressBar = false;
            }).catch(e => {
                    that.showProgressBar = false;
                    showError( that.$toast, "reset Cache:", e)

            }); 
	},
	nodes() 
	 {
		 let that = this;


        HTTP.get( serviceAPI+"/getNodes/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId)
           .then( response => 
           { 
			   alert(JSON.stringify(response.data))
           }).catch(e => {
                   that.showProgressBar = false;
                   showError( that.$toast, "getNodes:", e)

           }); 
	},
	dbCopyPrepare() 
	{
		 let that = this;
		 
         HTTP.post( copyAPI+"/prepareCopyTenant/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId, this.copyRequest)
         .then( response => 
            { 
             	 that.copyInfo = response.data;
             	 that.data = {};
             	 that.tenants = that.copyInfo.tenants;
             	 if ( !that.accessPointsActive.length )
             	 {
	             	 that.accessPointsActive = [];
	             	 for ( let api in that.copyInfo.accessPoints )
	             	 {
	             	 	that.accessPointsActive[api] = true;
	             	 }
	             	 
	              }
            }).catch(e => {
                    
                    showError( that.$toast, "´copyDB:", e)

            });
    },
	dbCopyIntern(myId, api) 
	 {
		 let that = this;
		 this.activeServer = sessionStorage.server+":"+sessionStorage.port;
		 this.copyRequest.name = this.copyInfo.tenant.name;
		 this.copyRequest.shortname = this.copyInfo.tenant.shortname;
		 this.copyRequest.accessPoints = [];
		 for ( let api in that.copyInfo.accessPoints )
     	 {
     	 	if ( this.accessPointsActive[api] )
     	 	{
     	 		this.copyRequest.accessPoints.push( this.copyInfo.accessPoints[api] );
     	 	}
     	 }
     	 //that.showProgressBar = false;
     	 //alert( JSON.stringify(this.copyRequest));
     	 
         api.post( copyAPI+"/copyAndCreateTenant/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+myId, this.copyRequest)
            .then( response => 
            { 
               that.data = response.data;
			   that.showProgressBar = false;
			   that.resetCache();
            }).catch(e => {
                    that.showProgressBar = false;
                    showError( that.$toast, "copyDB:", e)

            });
    	
	 },
	 createURL( tenant) 
	 {
	 	let fullURL = location.href;
	 	let url = fullURL.replace( this.$route.path, '/home/'+tenant.identifier);
	 	this.url = url;
	 	return url;
	 }
	 
    },
    created() {
    	this.activeServer = sessionStorage.server+":"+sessionStorage.port;
    	this.servers = ["192.168.178.32:89","192.168.178.32:90"];
    	this.copyRequest.from = sessionStorage.tenant;
    	this.copyRequest.fromId = sessionStorage.tenantId;
    	this.setName();
    	testServer( sessionStorage.server, sessionStorage.port).then( p => {
   		 	this.activeServerName = p;
     
	 	});
    }
}
</script>
<style lang="scss" scoped>
.myButton {
	//width: 18%;
    padding: 6px 8px;
    outline: none;
    border-radius: 3px;
    height: 22pt;
    font-size: 9pt;
    background-color: #eef;
    border: 1px outset #aaa;
    color: rgb(0, 0, 0);
    margin-top: 4pt;
    margin-right: 4pt;
    margin-bottom: 4pt;
}
</style>